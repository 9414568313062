const CryptoJS = require("crypto-js");
const encryptText = "abcdefghijuklmno0123456789012345";



export function decrypt(textToDecrypt) {

    const bytes = CryptoJS.AES.decrypt(textToDecrypt, encryptText);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);

    return JSON.parse(originalText);
}

export function encrypt(textToEncrypt) {

    const cypData = CryptoJS.AES.encrypt(JSON.stringify(textToEncrypt), encryptText);
    let payload = { payload: cypData.toString() };
    return payload.payload;
}